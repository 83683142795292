* {
	box-sizing: border-box;
}

body {
	margin: 0;
	background-color: #F5F5DC
}

.pap {
	padding-top:10px;
}

.nav {
	background-color: #333;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 2rem;
	padding: 0 1rem;
	width: 100%;
}

.site-title {
	font-size: 2rem;
}

.nav ui {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	gap: 1rem;
}

.nav ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	gap: 2rem;
}

.nav a {
	color: inherit;
	text-decoration: none;
	height: 100%;
	display: flex;
	align-items: center;
	padding: .25rem;
}

.nav li.active {
	background-color: #555;
}

.nav li:hover {
	background-color: #777;
}

.app-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.paper-container {
	display: flex;
	justify-content: center;
	width: 80%;
	height: 80%;
	margin: 2em;
}

.paper {
	background-color: white;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}